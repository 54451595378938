html {
  font-size: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
}

html::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
}

html::-webkit-scrollbar-thumb {
  background-color: #d0021b;
  border-radius: 1rem;
}

body {
  margin: 0px;
}

* {
  animation-fill-mode: forwards !important;
}

div [role="dialog"] {
  background-color: #212427;
}

div [role="dialog"] h1 {
  color: #f5f5f7;
}
